.chatbot-wrapper {
  z-index: 9999;
}

.modal-backdrop {
  width: 100%;
  height: 100%;
  z-index: 99995;
}

.feedback-wrapper .modal-header {
  background-color: #fd2d55;
  color: #fff;
}

.feedback-wrapper .modal-header .inline-icon {
  background-color: #fff;
  color: #fd2d55;
  border-radius: 50%;
  width: 56px;
  height: 56px;
}

.feedback-wrapper .modal-header .inline-icon path {
  transform-origin: 250px 250px;
        transform: scale(0.5);
        -ms-transform: scale(0.5);
        -webkit-transform: scale(0.5);
}

.feedback-wrapper .modal-header .btn-close{
  -webkit-filter: invert(100%);
  filter: invert(100%);
}

.feedback-wrapper {
  position: fixed;
  z-index: 99999;
  background: transparent;
  height: 100vh;
  top: 0px;
  width: 100%;
  text-align: left;
}

.feedback-copy {
  max-height: 200px;
  overflow-y: auto;
}

.chatbot-wrapper .btn:focus,
.chatbot-wrapper .btn:active {
  outline: none !important;
  box-shadow: none;
}

/* Remove outline for non-keyboard :focus */
.chatbot-wrapper *:focus:not(.focus-visible) {
  outline: none;
}

/* Optional: Customize .focus-visible */
.chatbot-wrapper .focus-visible {
  outline-color: #fd2d55;
}

.chatbot-wrapper .btn {
  background-color: #fd2d55;
}

.chatbot-wrapper a:link {
  text-decoration: none;
}

.chatbot-wrapper .inline-icon {
  position: relative;
  top: -.1rem;
}

.chatbot-wrapper .thumb-up:hover,
.chatbot-wrapper .thumb-down:hover {
  color: #444!important;
}

.chatbot-wrapper .chatbot-loading-wrapper {
  padding-top: 10px;
}

.chatbot-wrapper .bouncing-loader {
  display: flex;
  justify-content: center;
}

.chatbot-wrapper .bouncing-loader > div {
  width: .5rem;
  height: .5rem;
  margin: 1.5px 3px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

.chatbot-wrapper {
  position: fixed;
  width: 320px;
  bottom: 20px;
  right: 20px;
}

@media only screen and (max-width: 376px) {
  .chatbot-wrapper {
    position: fixed;
    width: calc(100% - 20px);
    bottom: 10px;
    right: 10px;
  }
}

.chatbot-wrapper:not(.open) {
  width: 70px;
  height: 70px;
  max-height: 70px;
  border-radius: 50%;
  transition: 0.2s ease-in-out;;
}

.chatbot-wrapper:not(.open):hover {
  transform: scale(1.1);
}

.chatbot-wrapper .chatbot-container {
  border-radius: 12px;
  background-color: #e5e5e5;
  padding: 0;
  box-shadow: 0 4px 16px rgb(0 0 0 / 25%);
  transition: max-height .5s;
  max-height: 1000px;
}

.chatbot-wrapper:not(.open) .chatbot-container {
  width: 70px;
  height: 70px;
  max-height: 70px;
  border-radius: 50%;
}

.chatbot-wrapper .chatbot-header {
  position: relative;
  border-radius: 12px 12px 0 0;
  display: flex;
  height: 80px;
  background-color: #fcfcfc;
}

.chatbot-wrapper .infocircle {
  cursor: pointer;
  font-size: 12px;
}

.chatbot-wrapper .disclaimer {
  position: absolute;
  background-color: #555;
  color: #fff;
  text-align: center;
  font-size: 13px;
  border-radius: 3px;
  padding: 6px;
  right: 8px;
  margin-top: 8px;
  width: calc(100% - 16px);
  z-index: 99999;
  transition: opacity 1s;
  pointer-events: none;
}

.chatbot-wrapper .email-info {
  position: absolute;
  background-color: #fcfcfc;
  color: #333;
  text-align: center;
  font-size: 13px;
  line-height: 17px;
  height: calc(100% - 8px);
  border-radius: 3px;
  padding: 6px 84px 6px 30px;
  left: 0px;
  top: 0px;
  margin-top: 8px;
  width:100%;
  z-index: 999;
  transition: opacity .2s;
}

.chatbot-wrapper .disclaimer .infoclose,
.chatbot-wrapper .email-info .infoclose {
  position: absolute;
  pointer-events: all;
  cursor: pointer;
  top: 4px;
  right: 4px;
}

.chatbot-wrapper .email-info .infoclose {
  left: 16px;
}

.chatbot-wrapper .disclaimer.hide,
.chatbot-wrapper .email-info.hide {
  opacity: 0;
  pointer-events: none;
}

.chatbot-wrapper .chatbot-header-inner::before {
  content: '';
  z-index: 1;
  position: absolute;
  left: 0;
  width: 100%;
  border-radius: 50%;
  height: 16px;
  background-color: #fcfcfc;
  bottom: -7.5px;
}

.chatbot-wrapper .chatbot-header-img {
  width: 3rem;
  height: 3rem;
}

.chatbot-wrapper .chatbot-header-img img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.chatbot-wrapper:not(.open) .chatbot-header {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.chatbot-wrapper .chatbot-header .open-toggle-button {
  color: #999!important;
  font-size: 28px;
  border-radius: 50%;
  background-color: transparent!important;
  border: none !important;
  position: absolute;
  transition: none;
  z-index: 99999;
}

.chatbot-wrapper .chatbot-header .email-button {
  color: #999!important;
  font-size: 24px;
  border-radius: 50%;
  background-color: transparent!important;
  border: none !important;
  position: absolute;
  right: 44px;
  top: 9px;
  transition: none;
  z-index: 99999;
}

.chatbot-wrapper .chatbot-header .open-toggle-button:hover,
.chatbot-wrapper .chatbot-header .open-toggle-button:active,
.chatbot-wrapper .chatbot-header .open-toggle-button:focus,
.chatbot-wrapper .chatbot-header .email-button:hover {
  color: #777!important;
}

.chatbot-wrapper.open .chatbot-header .open-toggle-button{ 
  right: 5px;
  top: 5px;
}

.chatbot-wrapper:not(.open) .chatbot-header .open-toggle-button {
  width: 100%;
  height: 100%;
  border-radius: 50%;

  background-color: #fd2d55!important;
  color: #fff!important;
}

.chatbot-wrapper:not(.open) .chatbot-header .open-toggle-button:focus,
.chatbot-wrapper:not(.open) .chatbot-header .open-toggle-button:active {
  color: #ffd2db!important;
  background-color: #e6264c!important;
}

.chatbot-wrapper:not(.open) .chatbot-header .open-toggle-button svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.chatbot-wrapper:not(.open) .chatbot-messages-wrapper,
.chatbot-wrapper:not(.open) .chatbot-input {
  display: none;
}

.chatbot-wrapper .chatbot-messages-wrapper {
  overflow-y: auto;
  height: calc(100vh - 295px);
  max-height: 600px;
  padding: 20px;
}

@media only screen and (max-height: 500px) {
  .chatbot-wrapper .chatbot-messages-wrapper {
    height: calc(100vh - 180px);
  }
}

.chatbot-wrapper .chatbot-message-img {
  width: 1.5rem;
  height: 1.5rem;
  margin-left: 5px;
  display: inline-block;
  position: absolute;
  bottom: 0;
}

.chatbot-wrapper .chatbot-message-img img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
}

.chatbot-wrapper .chatbot-message {
  margin-bottom: 10px;
  position: relative;
}

.chatbot-wrapper .chatbot-message:not(:last-child) {
  margin-bottom: 20px;
}

.chatbot-wrapper .chatbot-message-header {
  font-size: 12px;
  margin-left: 4px;
}

.chatbot-wrapper .system .chatbot-message-header {
  margin-left: calc(15px + 1.5rem);
}

.chatbot-wrapper .user .chatbot-message-header {
  text-align: right;
}

.chatbot-wrapper .chatbot-message-body {
  background-color: #f0f0f0;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #dbdbdb;
}

.chatbot-wrapper .system .chatbot-message-body {
  border-radius: 10px 10px 10px 2px;
  margin-right: 10px;
  margin-left: calc( 1.5rem + 10px);
  display: inline-block;
}

.chatbot-wrapper .user .chatbot-message-body {
  background-color: #00a6ed;
  border: 1px solid #00a6ed;
  color: #fff;
  border-radius: 10px 10px 2px 10px;
  margin-left: 60px;
}

.chatbot-wrapper .chatbot-message.message-pending .chatbot-message-body {
  animation-duration: 4s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;
  background: darkgray;
  background-size: 400% 100%;
  background: linear-gradient(to right, #f0f0f0 0%, #e9e9e9 50%, #f0f0f0 99%);
}

.chatbot-wrapper .chatbot-message.user.message-pending .chatbot-message-body {
  background: linear-gradient(to right, #00a6ed 0%, #0091cf 50%, #00a6ed 99%);
}

.chatbot-wrapper .chatbot-message-body.quicklink {
  background-color: #fd2d55;
  border-color: #e9284f;
  color: #fff;
  text-align: center;
  text-decoration: none;
  margin-top: -5px;
  margin-bottom: 30px;
}

.chatbot-wrapper .chatbot-input .input-group,
.chatbot-wrapper .chatbot-input .form-control,
.chatbot-wrapper .chatbot-input .btn {
  min-height: 44px;
}

.chatbot-wrapper .chatbot-input .form-control {
  border-radius: 0 0 0 6px;
  border:none;
  line-height: 22px;
  padding: 10px;
}

.chatbot-wrapper .chatbot-input .btn {
  background-color: #00a6ed;
  border: none;
  border-radius: 0 0 6px 0;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-.25rem);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes placeHolderShimmer{
  0%{
      background-position: -468px 0
  }
  100%{
      background-position: 468px 0
  }
}
